import React from "react";

import moment from "moment";

export const useTotal = (list) =>
    (list &&
        Object.values(list).reduce(
            (acc, cur) => acc + parseFloat(cur.expense),
            0,
        )) ||
    0;

export const userTotal = (data, role) => {
    if (!data) return 0;

    if (data && role) {
        const outlaysRoleArray = Object.keys(data).filter(
            (key) => data[key].role === role && data[key].role,
        );

        return outlaysRoleArray.reduce(
            (acc, cur) => acc + parseFloat(data[cur].expense),
            0,
        );
    }
    if (data) {
        return Object.values(data)
            .map((c) => parseFloat(c.expense))
            .reduce((a, c) => a + c, 0);
    }
};

export const useParseFloatTo = (x, to = 2) => Number.parseFloat(x).toFixed(to);

export const getStringValueToNumber = (val) =>
    val &&
    val
        .split(".")
        .map((c, i) => (i === 1 ? parseInt(c, 10) / 100 : parseInt(c, 10)))
        .reduce((acc, cur) => acc + cur, 0);

export const computedCSSValues = (values) => {
    if (typeof values === "number") return `${values}px`;
    return values;
};

export const sortList = (list, key) => list.sort((a, b) => a[key] - b[key]);

export const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
};

export const sortBy = (array, key, type) => {
    const types = {
        old: array.sort((a, b) => moment(a[key]) - moment(b[key])),
        recent: array.sort((a, b) => moment(a[key]) - moment(b[key])).reverse(),
        growing: array.sort((a, b) => a[key] - b[key]),
        increasing: array.sort((a, b) => a[key] - b[key]).reverse(),
    };
    return types[type];
};

export const makeArray = (n, startValue = 0) => {
    if (!n || typeof n !== "number") return [];
    return Array.from({ length: n }, (v, k) => k + startValue);
};

export const colorShaders = (colors, untilOpacity = 99) => {
    const shaders = {};
    const opacityLength = makeArray(untilOpacity);
    for (const color in colors) {
        for (const opacity in opacityLength) {
            if (opacity === "0") {
                shaders[color] = colors[color];
            } else {
                const lightString = /Light/.test(color)
                    ? `${color}`
                    : `${color}Light`;

                shaders[`${lightString}${opacity.padStart(2, "0")}`] = hexToRGB(
                    colors[color],
                    `0.${opacity.padStart(2, "0")}`,
                );
            }
        }
    }
    return shaders;
};

export const isEmptyObj = (obj) => Object.keys(obj).length === 0;
