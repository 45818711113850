import { createSelector, createSlice } from "@reduxjs/toolkit";
import objectPath from "object-path";

export const slice = createSlice({
    name: "modalEditOrDeleteUiSlice",
    initialState: {
        isOpen: false,
        isOpenUpdateOutlayModalUi: false,
        isOpenDeleteConfirmOutlayModalUi: false,
        outlaysItemSelectedOnModalUi: {},
    },
    reducers: {
        openedModalEditOrDeleteUi: (state, action) => {
            state.isOpen = action.payload;
        },
        toggleModalEditOrDeleteUi: (state) => {
            state.isOpen = !state.isOpen;
        },
        openedUpdateOutlayModalUi: (state, action) => {
            state.isOpenUpdateOutlayModalUi = action.payload;
        },
        openedDeleteConfirmOutlayModalUi: (state, action) => {
            state.isOpenDeleteConfirmOutlayModalUi = action.payload;
        },
        toggleDeleteConfirmOutlayModalUi: (state) => {
            state.isOpenDeleteConfirmOutlayModalUi = !state.isOpenDeleteConfirmOutlayModalUi;
        },
        toggleUpdateOutlayModalUi: (state) => {
            state.isOpenUpdateOutlayModalUi = !state.isOpenUpdateOutlayModalUi;
        },
        getOutlayItemOnEditModalUi: (state, action) => {
            state.outlaysItemSelectedOnModalUi = action.payload;
        },
    },
});

export const {
    // Edit or Delete Outlay Modal
    openedModalEditOrDeleteUi,
    toggleModalEditOrDeleteUi,
    // Update Outlays Modal
    openedUpdateOutlayModalUi,
    toggleUpdateOutlayModalUi,
    // delete Outlay Modal
    openedDeleteConfirmOutlayModalUi,
    toggleDeleteConfirmOutlayModalUi,
    // Get item outlays on Modal
    getOutlayItemOnEditModalUi,
} = slice.actions;

const selectModalUi = (state) => state.modalEditOrDeleteUiStore;

export const selectIsOpenModalEditOrDelete = createSelector(
    selectModalUi,
    (state) => state.isOpen,
);

export const selectIsOpenUpdateOutlayModal = createSelector(
    selectModalUi,
    (state) => state.isOpenUpdateOutlayModalUi,
);

export const selectorOutlayItemEditModalUi = createSelector(
    selectModalUi,
    (state) => objectPath.get(state, "outlaysItemSelectedOnModalUi", {}),
);

export const selectorIsOpenDeleteConfirmOutlayItemModalUi = createSelector(
    selectModalUi,
    (state) => state.isOpenDeleteConfirmOutlayModalUi,
);

export default slice.reducer;
