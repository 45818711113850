import React from "react";

import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import LoginPage from "./components/app/Auth/LoginPage";
import theme from "./components/ui/Theme";
import "firebase/database";
import rootReducer from "./Stores/RootReducer";

const GlobalStyle = createGlobalStyle`
    @font-face {
        src: url(${theme.fontSrc});
    }
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        line-height: 1.25;
      -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body, #root {
	height: 100%;
  }
  body {
    font-family: ${theme.fontFamily};
	background: ${theme.colors.greyLight};
	a {
      text-decoration: none;
	}
  }
  
  #modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
  }
`;

const lang = navigator.language;

ReactDOM.render(
    <Provider store={rootReducer}>
        <IntlProvider locale={lang}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <LoginPage />
            </ThemeProvider>
        </IntlProvider>
    </Provider>,
    document.getElementById("root"),
);
