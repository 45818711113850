import { colorShaders } from "../helpers/helpers";

const colorsPallet = {
    black: "#2e2658",
    realBlack: "#000000",
    blueLight: "#84e2f7",
    blue: "#5335f1",
    blueDark: "#0f015a",
    white: "#ffffff",
    pinkLight: "#5335f1",
    purpleLight: "#e3dfebc7",
    pink: "#8d65ed",
    greyLight: "#eae8f4",
    greyMedium: "#aca4bf",
    greyDark: "#706982",
    hovered: "#f5f3f824",
    error: "#f13567",
};

class Theme {
    fontSrc =
        "https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap";

    fontFamily = "Commissioner";

    colors = {
        ...colorShaders(colorsPallet),
    };

    fontWeight = {
        extraBold: "800",
        semiBold: "600",
        normal: "400",
        light: "300",
    };

    fontSize = {
        extraBig: "35px",
        veryBig: "26px",
        big: "18px",
        normal: "14px",
        small: "12px",
        verySmall: "10px",
    };

    radius = {
        light: 5,
        round: 10,
        high: 20,
    };

    gradientPrimary = `linear-gradient(140deg, ${this.colors.blue}, ${this.colors.pink})`;
    gradientSecondary = `linear-gradient(140deg, ${this.colors.pink}, ${this.colors.pinkLight})`;

    shadow = {
        high: `0px 15px 40px -5px ${this.colors.blue}50`,
        highDark: `0 0 30px ${this.colors.realBlack}1f`,
        lightDark: `0 5px 30px ${this.colors.purpleLight}`,
    };
}

export default new Theme();
