export const API_COLLECTION = {
    USERS: "users",
    OUTLAYS: "outlays",
    FIXED_OUTLAYS: "fixed-outlays",
    GROUPS: "groups",
};

export const REDUX_CONFIG = {
    ENABLE_LOGGER_REDUX: false,
};
