import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import firebase from "firebase";

import { addFixedOutlays, allFixedOutlays } from "../api";

export const fetchFixedOutlaysDocId = createAsyncThunk(
    "fixedOutlays/fetchFixedOutlaysDocId",
    async (docId, thunkAPI) => allFixedOutlays.where("id", "==", docId).get(),
);

export const slice = createSlice({
    name: "fixedOutlaysSlice",
    initialState: {
        fixedOutlays: [], // All fixed Outlays List
        fixedOutlaysGuest: [],
        fixedOutlaysByDoc: {},
        fixedOutlaysSelected: {},
        currentFixedOutlaysOwner: [],
    },
    reducers: {
        getAllFixedOutlaysByOwnerSlice: (state, action) => {
            state.fixedOutlays = action.payload;
        },
        getFixedOutlaysSelected: (state, action) => {
            state.fixedOutlaysSelected = action.payload;
        },
        getCurrentFixedOutlaysByDocId: (state, action) => {
            state.currentFixedOutlaysOwner = action.payload;
        },
        getFixedOutlaysByDocId: (state, action) => {
            state.fixedOutlaysByDoc = action.payload;
        },
        getFixedOutlaysGuest: (state, action) => {
            state.fixedOutlaysGuest = action.payload;
        },
        setSelectedFixedOutlays: (state, action) => {
            if (!action.payload.currentFixedOutlaysDocId) {
                addFixedOutlays(action.payload.newFixedOutlaysDocId, {
                    regularBill: firebase.firestore.FieldValue.arrayUnion(
                        action.payload.typeId,
                    ),
                });
                return;
            }

            // Empty Value
            if (action.payload.newFixedOutlaysId === "empty") {
                addFixedOutlays(action.payload.currentFixedOutlaysDocId, {
                    regularBill: firebase.firestore.FieldValue.arrayRemove(
                        action.payload.typeId,
                    ),
                });
                return;
            }

            // Remove current docId on DB
            addFixedOutlays(action.payload.currentFixedOutlaysDocId, {
                regularBill: firebase.firestore.FieldValue.arrayRemove(
                    action.payload.typeId,
                ),
            });

            // Add the new docId on DB
            addFixedOutlays(action.payload.newFixedOutlaysDocId, {
                regularBill: firebase.firestore.FieldValue.arrayUnion(
                    action.payload.typeId,
                ),
            });
        },
    },
});

export const {
    getFixedOutlaysSelected,
    getAllFixedOutlaysByOwnerSlice,
    setSelectedFixedOutlays,
    getFixedOutlaysByDocId,
    getFixedOutlaysGuest,
    getCurrentFixedOutlaysByDocId,
} = slice.actions;

const selectFixedOutlays = (state) => state.fixedOutlaysStore;
const selectUser = (state) => state.userStore.user;

export const selectorCurrentFixedOutlaysOwner = createSelector(
    selectFixedOutlays,
    selectUser,
    (state, user) => {
        const owner = state.currentFixedOutlaysOwner.find(
            (fixed) => fixed.owner.id === user.id,
        );
        const guest = state.currentFixedOutlaysOwner.find(
            (fixed) => fixed.owner.id !== user.id,
        );
        return { owner, guest };
    },
);

export const selectorAllFixedOutlays = createSelector(
    selectFixedOutlays,
    (state) => state.fixedOutlays,
);

export const selectorFixedOutlaysGuest = (docId) =>
    createSelector(selectFixedOutlays, (state) =>
        state.fixedOutlaysGuest.find((item) =>
            item.regularBill.includes(docId),
        ),
    );

export const selectorFixedOutlaysOwner = (docId) =>
    createSelector(selectFixedOutlays, (state) =>
        state.fixedOutlays.find((item) => item.regularBill.includes(docId)),
    );

export const selectorFixedOutlaysByDoc = createSelector(
    selectFixedOutlays,
    (state) => state.fixedOutlaysByDoc,
);

export default slice.reducer;
