import React from "react";

import { getUser } from "../../../api";
import { createFetcher } from "../../../decorateurs/createFetcher";
import { getAuthState, getUserSlice } from "../../../slices/userSlice";
import Loading from "../atoms/Loading";

const FetchUser = ({ dispatch, auth, children }) => {
    const [data, isLoading] = getUser(auth.userIUD);

    if (isLoading) return <Loading />;
    dispatch(getUserSlice(data));
    dispatch(getAuthState(auth));

    return children({ isLoading });
};

export default createFetcher(FetchUser);
