import styled from "styled-components";

import {
    aspects,
    flexing,
    positioning,
    sizing,
    spacing,
    texting,
} from "./attributesCSS";

export const getFirstDefined = (...args) => {
    for (let i = 0; i < args.length; i += 1) {
        if (args[i] !== undefined) return args[i];
    }
    return undefined;
};

// Refactor
// TODO: You can't pass all props in this case
const listOfPropsDontPass = [
    "color",
    "display",
    "position",
    "justify",
    "align",
    "borderRadius",
    "borderColor",
    "fontSize",
    "background",
    "boxShadow",
    "size",
    "fill",
    "column",
    "gap",
    "columnGap",
    "weight",
    "accent",
    "maxW",
    "minW",
    "my",
    "mx",
    "mt",
    "mb",
    "ml",
    "mr",
    "m",
    "py",
    "px",
    "pt",
    "pb",
    "pl",
    "pr",
    "p",
    "b",
    "h",
    "top",
    "bottom",
    "left",
    "right",
    "z",
    "x",
    "y",
    "height",
    "minHeight",
    "minH",
    "maxHeight",
    "maxH",
    "w",
    "width",
    "minWidth",
    "maxWidth",
    "colorShadow",
    "fullWidth",
    "gridColumn",
    "overflowHide",
    "radBottom",
    "radTop",
    "radRight",
    "radLeft",
    "hovered",
    "rounded",
    "customColumn",
    "flex",
];

export const reflex = (Component) =>
    styled(Component).withConfig({
        shouldForwardProp: (prop, defaultValidatorFn) =>
            !listOfPropsDontPass.includes(prop) && (prop, defaultValidatorFn),
    })((props) => ({
        ...aspects(props),
        ...flexing(props),
        ...spacing(props),

        // Grid
        gap: props.gap,
        gridColumn: props.gridColumn,

        // POSITIONS
        ...positioning(props),

        // SCROLL
        scrollSnapType: props.scrollSnapType,
        scrollSnapAlign: props.scrollSnapAlign,
    }));
