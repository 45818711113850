import { createSlice, createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { get } from "object-path";

import { useTotal } from "../components/helpers/helpers";

export const slice = createSlice({
    name: "outlaysSlice",
    initialState: {
        outlays: {},
        filterOutlaysList: [
            {
                name: "le plus récent",
                id: "recent",
            },
            {
                name: "le plus ancien",
                id: "old",
            },
            {
                name: "le moins cher",
                id: "growing",
            },
            {
                name: "le plus cher",
                id: "increasing",
            },
        ],
        currentFilter: "recent",
    },
    reducers: {
        getOutlaysSlice: (state, action) => {
            const { data, sortBy } = action.payload;

            if (typeof data === "undefined") state.outlays = {};
            else
                switch (sortBy) {
                    case "old":
                        state.outlays = Object.fromEntries(
                            Object.entries(data).sort(
                                ([, a], [, b]) =>
                                    moment(a.createAt) - moment(b.createAt),
                            ),
                        );
                        break;
                    case "recent":
                        state.outlays = Object.fromEntries(
                            Object.entries(data)
                                .sort(
                                    ([, a], [, b]) =>
                                        moment(a.createAt) - moment(b.createAt),
                                )
                                .reverse(),
                        );
                        break;
                    case "growing":
                        state.outlays = Object.fromEntries(
                            Object.entries(data).sort(
                                ([, a], [, b]) => a.expense - b.expense,
                            ),
                        );
                        break;
                    case "increasing":
                        state.outlays = Object.fromEntries(
                            Object.entries(data)
                                .sort(([, a], [, b]) => a.expense - b.expense)
                                .reverse(),
                        );
                        break;
                    default:
                        state.outlays = action.payload.data;
                }
        },
        getCurrentFilter: (state, action) => {
            state.currentFilter = action.payload;
        },
    },
});

export const { getOutlaysSlice, getCurrentFilter } = slice.actions;

const selectOutlays = (state) => state.outlaysStore.outlays;
const selectFiltersList = (state) => state.outlaysStore.filterOutlaysList;
const selectCurrentFilter = (state) => state.outlaysStore.currentFilter;

export const outlaysSelector = createSelector(selectOutlays, (state) => state);

export const outlaysByRoleSelector = createSelector(selectOutlays, (state) => {
    const getRole = (role) => {
        const outlaysOwnerList = [];
        for (const key in state) {
            if (state[key].role === role) {
                outlaysOwnerList.push({ id: key, ...state[key] });
            }
        }
        return outlaysOwnerList;
    };
    return {
        outlaysOwner: getRole("owner"),
        outlaysGuest: getRole("guest"),
    };
});

export const totalOutlays = createSelector(selectOutlays, (state) =>
    useTotal(state),
);

export const selectorFilterOutlaysList = createSelector(
    selectFiltersList,
    (state) => state,
);

export const selectorCurrentFilterOutlays = createSelector(
    selectCurrentFilter,
    (state) => state,
);

export default slice.reducer;
