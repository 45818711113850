import React from "react";

import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { reflex } from "../../reflex";

const textStyled = {
    fullwidth: css`
        width: 100%;
    `,
};

const commonsStyled = css`
    font-family: ${(p) => p.theme.fontFamily};
    color: ${(p) => p.theme.colors[p.color]};
    font-weight: ${(p) => p.theme.fontWeight[p.weight]};
    font-size: ${(p) => p.theme.fontSize[p.size]};
    ${(p) => (p.fullwidth ? textStyled.fullwidth : undefined)};
`;

const primary = css`
    color: ${(p) => p.theme.colors.black};
`;

const secondary = css`
    color: ${(p) => p.theme.colors.blue};
`;

const colors = {
    primary,
    secondary,
};

const ellipsisCSS = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TextStyled = reflex(styled.p`
    background: ${(p) => p.theme.colors[p.background] || p.background};
    ${commonsStyled};
    ${(p) => p.ellipsis && ellipsisCSS};
    ${(p) => colors[p.color]};
`);

const Text = ({ as, children, to, ...props }) => {
    const hasLink = to ? { as: NavLink, to } : { as };
    return (
        <TextStyled {...hasLink} {...props}>
            {children}
        </TextStyled>
    );
};

Text.propTypes = {
    align: PropTypes.string,
    size: PropTypes.oneOf(["veryBig", "big", "normal", "small"]),
    weight: PropTypes.oneOf(["extraBold", "semiBold", "normal"]),
    nowrap: PropTypes.bool,
    ellipsis: PropTypes.bool,
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    as: PropTypes.string,
};

Text.defaultProps = {
    align: "left",
    size: "normal",
    weight: "normal",
    nowrap: undefined,
    ellipsis: undefined,
    fontSize: 14,
    color: "primary",
    as: "p",
};

export default Text;
