import React, { Suspense, lazy } from "react";

import { hot } from "react-hot-loader/root";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";

import { useAuthStateChange } from "../../hooks/useFirestoreFunction";
import Loading from "../atoms/Loading";
import FetchUser from "../Fetcher/FetchUser";

const App = lazy(() => import("../../../App"));
const Auth = lazy(() => import("./Auth"));

const LoginPage = () => {
    const { isSignIn } = useAuthStateChange();

    return (
        <Router>
            <Switch>
                <Route path="/">
                    <Suspense fallback={<Loading />}>
                        {isSignIn.isSigned ? (
                            <FetchUser auth={isSignIn}>
                                {({ isLoading }) =>
                                    isLoading ? (
                                        <Loading />
                                    ) : (
                                        <>
                                            <Redirect
                                                exact
                                                from="/"
                                                to="/app/groups/owner"
                                            />
                                            <Route
                                                strict
                                                path="/app"
                                                component={App}
                                            />
                                        </>
                                    )
                                }
                            </FetchUser>
                        ) : (
                            <>
                                <Redirect exact from="/" to="/auth/sing-in" />
                                <Route
                                    strict
                                    path="/auth"
                                    render={() => <Auth />}
                                />
                            </>
                        )}
                    </Suspense>
                </Route>
            </Switch>
        </Router>
    );
};

export default hot(LoginPage);
