import React from "react";

import styled, { keyframes } from "styled-components";

import Text from "../../ui/atoms/Text/Text";

const scale = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Scaler = styled(Text)`
    animation: ${scale} 1s linear backwards infinite;
    display: grid;
    justify-content: center;
    border: 4px solid ${(p) => p.theme.colors.blue};
    height: 20px;
    width: 20px;
    border-top: white solid;
    border-radius: 50px;
`;

const emojis = {
    money: "💸",
};

const Loading = ({ emoji, fontSize }) => <Scaler style={{ fontSize }} />;

export default Loading;
