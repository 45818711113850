import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import logger from "redux-logger";

import { REDUX_CONFIG } from "../constants";
import fixedOutlaysSlice from "../slices/fixedOutlaysSlice";
import groupsSlice from "../slices/groupsSlice";
import modalAddSpendUiSlice from "../slices/modalAddSpendUiSlice";
import modalEditOrDeleteFixedOutlaysUiSlice from "../slices/modalEditOrDeleteFixedOutlaysUiSlice";
import modalEditOrDeleteUiSlice from "../slices/modalEditOrDeleteUiSlice";
import outlaysSlice from "../slices/outlaysSlice";
import userSlice from "../slices/userSlice";

const rootReducer = combineReducers({
    // STORE
    outlaysStore: outlaysSlice,
    fixedOutlaysStore: fixedOutlaysSlice,
    userStore: userSlice,
    groupsStore: groupsSlice,
    // MODAL UI
    modalEditOrDeleteUiStore: modalEditOrDeleteUiSlice,
    modalAddSpendUiStore: modalAddSpendUiSlice,
    modalEditOrDeleteFixedOutlaysUiStore: modalEditOrDeleteFixedOutlaysUiSlice,
});

const enableLog = () => {
    if (REDUX_CONFIG.ENABLE_LOGGER_REDUX)
        return (getDefaultMiddleware) => getDefaultMiddleware().concat(logger);
    return (getDefaultMiddleware) => getDefaultMiddleware().concat();
};

const store = configureStore({
    reducer: rootReducer,
    middleware: enableLog(),
});

export default store;
