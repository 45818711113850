import { createSelector, createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: "modalAddSpendUiSlice",
    initialState: {
        isOpenAddSpendModal: false,
    },
    reducers: {
        openedAddSpendModalUi: (state, action) => {
            state.isOpenAddSpendModal = action.payload;
        },
        toggleAddSpendModalUi: (state, action) => {
            state.isOpenAddSpendModal = !state.isOpenAddSpendModal;
        },
    },
});

export const { openedAddSpendModalUi, toggleAddSpendModalUi } = slice.actions;

const selectAddSpendUi = (state) => state.modalAddSpendUiStore;

export const selectorIsAddSpendModalUi = createSelector(
    selectAddSpendUi,
    (state) => state.isOpenAddSpendModal,
);

export default slice.reducer;
