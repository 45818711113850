import { getFirstDefined } from "./reflex";

export const spacing = (props) => ({
    // SPACING
    // -- padding --
    paddingTop: getFirstDefined(props.pt, props.py, props.p),
    paddingBottom: getFirstDefined(props.pb, props.py, props.p),
    paddingLeft: getFirstDefined(props.pl, props.px, props.p),
    paddingRight: getFirstDefined(props.pr, props.px, props.p),
    // -- margin --
    marginTop: getFirstDefined(props.mt, props.my, props.m),
    marginBottom: getFirstDefined(props.mb, props.my, props.m),
    marginLeft: getFirstDefined(props.ml, props.mx, props.m),
    marginRight: getFirstDefined(props.mr, props.mx, props.m),
});

export const flexing = (props) => ({
    // FLEX
    flexAuto: props.flexAuto ? "1 1 auto" : undefined,
    flex: props.flexSize,
    flexGrow: props.flexGrow,
    flexWrap: props.wrap ? "wrap" : props.wrap,
    flexShrink: props.flexShrink,
    alignItems: props.alignItems || props.align || undefined,
    alignSelf: props.alignSelf || undefined,
    flexDirection: props.column ? "column" : undefined,
    justifyContent: props.justify || undefined,
    justifySelf: props.justifySelf || undefined,
    cursor: props.onClick && "pointer",
});

export const aspects = (props) => ({
    // ASPECTS
    display: props.flex ? "flex" : props.display,
    gridTemplateColumns: props.gridColumns,
    gridRowGap: props.gridRowGap,
    gridColumnGap: props.gridColumnGap,
    overflow: props.overflow && (props.overflow || "hidden"),
    overflowX: props.overflowX,
    overflowY: props.overflowY,
    opacity: props.opacity,
    boxSizing: props.boxSizing && (props.boxSizing || "border-box"),
    border: props.border,
    borderRadius: props.borderRadius,
    boxShadow: props.boxShadow,
});

export const texting = (props) => ({
    // TEXT
    textAlign: props.textAlign,
    whiteSpace: props.nowrap ? "nowrap" : undefined,
    textTransform: props.capitalize ? "capitalize" : props.textTransform,
});

export const sizing = (props) => ({
    // SIZES
    // -- width --
    width: props.w || props.size,
    minWidth: props.minW || props.size,
    maxWidth: props.maxW || props.size,
    // -- height --
    height: props.h || props.size,
    minHeight: props.minH || props.size,
    maxHeight: props.maxH || props.size,
});

export const positioning = (props) => ({
    // POSITIONS
    position: props.pos || props.position,
    left: props.left || props.x,
    right: props.right || props.r,
    top: props.top || props.y,
    bottom: props.bottom || props.b,
    zIndex: props.z,
});
