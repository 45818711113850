import React from "react";

import firebase from "firebase";

import db from "../../configFirebase";

export const useFirestoreFunction = (fnName) => {
    const [promiseStatus, setPromiseStatus] = React.useState(false);

    const firestoreFunction = async (formData) => {
        await setPromiseStatus(true);

        await firebase
            .functions()
            .httpsCallable(fnName)({ ...formData })
            .then(async (result) => {
                await setPromiseStatus(false);
            })
            .catch((error) => {
                setPromiseStatus(false);
            });
    };

    return [firestoreFunction, promiseStatus];
};

export const useDeleteFirestoreFunction = (fnName) => {
    const [promiseStatus, setPromiseStatus] = React.useState(false);

    const deleteFirestoreFunction = React.useCallback(
        async (docId, collectionId) => {
            if ((docId, collectionId)) {
                await setPromiseStatus(true);

                firebase
                    .functions()
                    .httpsCallable(fnName)({
                        docOutlayRef: docId,
                        docGroupRef: collectionId,
                    })
                    .then(async (result) => {
                        await setPromiseStatus(false);
                        console.log("Document delete succefully");
                    })
                    .catch((error) => {
                        setPromiseStatus(false);
                    });
            }
        },
        [fnName],
    );

    return [deleteFirestoreFunction, promiseStatus];
};

export const useAuthStateChange = () => {
    const [isSignIn, setSigned] = React.useState({
        isSigned: false,
        userIUD: "",
        emailVerified: "",
    });

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setSigned({
                    isSigned: true,
                    userIUD: user.uid,
                    emailVerified: user.emailVerified,
                });
                // User is signed in.
            } else {
                setSigned({ isSigned: false, userIUD: "" });
                console.log("USER NOT SIGNED");
                // No user is signed in.
            }
        });
    }, [db, setSigned]);

    return { isSignIn };
};

export const sendEmailVerification = () => {
    firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(() => {
            console.log("Email verification sent!");
        });
};
