import { createSelector, createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "modalEditOrDeleteFixedOutlaysUiSlice",
    initialState: {
        isOpenEditOrDeleteFixedOutlayUi: false,
        isOpenDeleteFixedOutlayUi: false,
        isOpenEditFixedOutlayUi: false,
        isOpenNewBillFixedOutlaysUi: false,
        fixedOutlayItemSelectedModalUi: {},
    },
    reducers: {
        openedEditOrDeleteFixedOutlayUi: (state, action) => {
            state.isOpenEditOrDeleteFixedOutlayUi = action.payload;
        },
        openedDeleteFixedOutlayUi: (state, action) => {
            state.isOpenDeleteFixedOutlayUi = action.payload;
        },
        openedEditFixedOutlayUi: (state, action) => {
            state.isOpenEditFixedOutlayUi = action.payload;
        },
        openedNewFixedOutlayUi: (state, action) => {
            state.isOpenNewFixedOutlayUi = action.payload;
        },
        openedNewBillFixedOutlaysUi: (state, action) => {
            state.isOpenNewBillFixedOutlays = action.payload;
        },
        getEditOrDeleteItemOnFixedOutlayUi: (state, action) => {
            state.fixedOutlayItemSelectedModalUi = action.payload;
        },
    },
});

export const {
    openedEditOrDeleteFixedOutlayUi,
    openedDeleteFixedOutlayUi,
    openedEditFixedOutlayUi,
    openedNewFixedOutlayUi,
    openedNewBillFixedOutlaysUi,
    getEditOrDeleteItemOnFixedOutlayUi,
} = slice.actions;

const selectFixedOutlaysModalUi = (state) =>
    state.modalEditOrDeleteFixedOutlaysUiStore;

export const selectorIsOpenFixedOutlaysModalUi = createSelector(
    selectFixedOutlaysModalUi,
    (state) => state.isOpenEditOrDeleteFixedOutlayUi,
);

export const selectorIsOpenDeleteFixedOutlaysModalUi = createSelector(
    selectFixedOutlaysModalUi,
    (state) => state.isOpenDeleteFixedOutlayUi,
);

export const selectorIsOpenEditFixedOutlaysModalUi = createSelector(
    selectFixedOutlaysModalUi,
    (state) => state.isOpenEditFixedOutlayUi,
);

export const selectorIsOpenNewFixedOutlaysModalUi = createSelector(
    selectFixedOutlaysModalUi,
    (state) => state.isOpenNewFixedOutlayUi,
);

export const selectorIsOpenNewBillFixedOutlaysModalUi = createSelector(
    selectFixedOutlaysModalUi,
    (state) => state.isOpenNewBillFixedOutlays,
);

export const selectorItemFixedOutlaysModalUi = createSelector(
    selectFixedOutlaysModalUi,
    (state) => state.fixedOutlayItemSelectedModalUi,
);

export default slice.reducer;
