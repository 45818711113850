// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

export const firebaseConfig = {
    apiKey: "AIzaSyDAeGHfeYWQ7IC17iD9THhX_pOvVu45RsE",
    authDomain: "shared-my-bill.firebaseapp.com",
    projectId: "shared-my-bill",
    storageBucket: "shared-my-bill.appspot.com",
    messagingSenderId: "546066927732",
    appId: "1:546066927732:web:a9db0b8ec9a3fcfe70c121",
    measurementId: "G-PWXG4QD8WG",
};

firebase.initializeApp(firebaseConfig);

export default firebase.firestore();
