import React from "react";

import firebase from "firebase";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";

import { useParseFloatTo, userTotal } from "./components/helpers/helpers";
import db from "./configFirebase";
import { API_COLLECTION } from "./constants";

export const { USERS, GROUPS, OUTLAYS, FIXED_OUTLAYS } = API_COLLECTION;

export const allFixedOutlays = db.collection(FIXED_OUTLAYS);

export const getAllFixedOutlays = (value) =>
    useCollectionData(db.collection(FIXED_OUTLAYS).where("id", "==", value));

const useUpdate = (collection) => (formData, docId) => {
    db.collection(collection)
        .doc(docId)
        .update({ ...formData })
        .catch((error) => console.log(error));
};

const useCreateDocument = (collection) => (formData) => {
    db.collection(collection)
        .add({ ...formData })
        .then((docRef) => console.log(`Document written with ID: ${docRef.id}`))
        .catch((error) => console.log(`Error adding document: ${error}`));
};

export const useGetDoc = (collection, docId) => {
    const [data, setData] = React.useState();

    React.useMemo(() => {
        db.collection(collection)
            .doc(docId)
            .onSnapshot((doc) => setData(doc.data()));
    }, [setData]);

    return data;
};

// **** GET ****

export const fetchUser = (userId) => {
    const [user, loading, error] = useDocumentData(
        db.doc(`${USERS}/${userId}`),
    );
    return { user, loading, error };
};

// --- USER ---

export const getUser = (id) => useDocumentData(db.doc(`users/${id}`));
export const getGuestUsers = (guestEmail) =>
    useCollectionData(db.collection(USERS).where("email", "==", guestEmail));

export const getOutlays = (docId) =>
    useDocumentData(db.doc(`${OUTLAYS}/${docId}`));

export const getOutlaysCollection = (docId) =>
    db.collection(OUTLAYS).doc(docId);

// --- GROUPS ---

export const getGroups = (docId) =>
    useCollectionData(db.collection(GROUPS).where("id", "==", docId));

export const getGroupsByRole = (role, id) =>
    useCollectionData(db.collection(GROUPS).where(role, "==", id));

// --- FIXED OUTLAYS ---

export const getFixedOutlaysList = (docId) =>
    useCollectionData(db.collection(FIXED_OUTLAYS).where("id", "==", docId));

export const getFixedOutlaysByDoc = (docId) =>
    useCollectionData(
        db
            .collection(FIXED_OUTLAYS)
            .where("regularBill", "array-contains", docId),
    );

export const getFixedOutlaysByOwner = (userId) =>
    useCollectionData(
        db.collection(FIXED_OUTLAYS).where("owner.id", "==", userId),
    );

export const getFixedOutlaysByGuest = (userEmail) =>
    useCollectionData(
        db.collection(FIXED_OUTLAYS).where("owner.email", "==", userEmail),
    );

// **** ADD ****

export const addFixedOutlays = (docId, formData) =>
    useUpdate(FIXED_OUTLAYS)(formData, docId);

export const addBill = async (formData) => useCreateDocument(GROUPS)(formData);

// **** UPDATE ****

export const updateUserProfile = async (formData, userId) => {
    useUpdate(USERS)(formData, userId);
};

export const updateUserEmail = async (formData, userId) => {
    const user = firebase.auth().currentUser;

    user.updateEmail(formData.email)
        .then(() => {
            useUpdate(USERS)(formData, userId);
            // Update successful
            // ...
        })
        .catch((error) => {
            // An error occurred
            // ...
        });
};

export const updateFixedOutlays = async (docId, formData) => {
    const { outlayId, ...formDataRest } = formData;
    useUpdate(FIXED_OUTLAYS)(
        { [`${OUTLAYS}.${formData.outlayId}`]: formDataRest },
        docId,
    );
};

export const updateOutlays = async (docId, formData) =>
    useUpdate(OUTLAYS)(formData, docId);

export const updateTotalFixedOutlays = (docId, formData) =>
    useUpdate(FIXED_OUTLAYS)(
        { total: useParseFloatTo(userTotal(formData)) },
        docId,
    );

export const updateBill = (docId, formData) =>
    useUpdate(GROUPS)(formData, docId);

// **** DELETE ****

export const deleteFixedOutlays = async (docId, id) => {
    useUpdate(FIXED_OUTLAYS)(
        {
            [`${OUTLAYS}.${id}`]: firebase.firestore.FieldValue.delete(),
        },
        docId,
    );
};

export const deleteFixedOutlaysDocument = async (docId, id) => {
    // await db.collection(`${FIXED_OUTLAYS}/${docId}`).update({
    //     [id]: firebase.firestore.FieldValue.delete(),
    // });
};

export const deleteOutlay = (docId, id) =>
    useUpdate(OUTLAYS)(
        {
            [id]: firebase.firestore.FieldValue.delete(),
        },
        docId,
    );
