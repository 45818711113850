import { createSelector, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { get } from "object-path";

import { sortList } from "../components/helpers/helpers";

export const slice = createSlice({
    name: "groupsSlice",
    initialState: {
        groups: {
            groupsOwner: [],
            groupsGuest: [],
        },
        currentGroupSelected: {},
    },
    reducers: {
        getGroupsOwnerSlice: (state, action) => {
            state.groups.groupsOwner = action.payload;
        },
        getGroupsGuestSlice: (state, action) => {
            state.groups.groupsGuest = action.payload;
        },
        getCurrentGroupSlice: (state, action) => {
            state.currentGroupSelected = action.payload;
        },
    },
});

export const {
    getGroupsOwnerSlice,
    getGroupsGuestSlice,
    getCurrentGroupSlice,
} = slice.actions;

export const selectGroups = (state) => get(state, "groupsStore.groups", []);
export const selectCurrentGroups = (state) =>
    state.groupsStore.currentGroupSelected;

export const selectorGroupsOwner = createSelector(
    selectGroups,
    (state) => state.groupsOwner,
);

export const selectorGroupsOwnerByDoc = (docId) =>
    createSelector(selectorGroupsOwner, (state) =>
        state.find((groups) => groups.id === docId),
    );

export const selectorGroupsGuest = createSelector(
    selectGroups,
    (state) => state.groupsGuest,
);

export const selectorGroupsGuestByDoc = (docId) =>
    createSelector(selectorGroupsGuest, (state) =>
        state.find((groups) => groups.id === docId),
    );

export const selectorCurrentGroup = createSelector(
    selectCurrentGroups,
    (state) => state[0],
);

export const selectorLatestGroupCreated = createSelector(
    selectGroups,
    (state) => {
        const groupSort = state.groupsOwner.map((item) => {
            if (item.isArchived) return;
            const now = moment();
            const createdAt = moment(item.createAt);
            return { id: item.id, seconds: now.diff(createdAt, "seconds") };
        });
        const latestGroup = sortList(groupSort, "seconds")[0];
        return state.groupsOwner.find((item) => item.id === latestGroup.id);
    },
);

export const selectorGroupsOwnerIsArchived = createSelector(
    selectorGroupsOwner,
    (state) => state.filter((item) => item.isArchived),
);

export const selectorGroupsOwnerIsActive = createSelector(
    selectorGroupsOwner,
    selectorLatestGroupCreated,
    (state, latestGroupCreated) =>
        state
            .filter(
                (item) => item.id !== latestGroupCreated.id && !item.isArchived,
            )
            .sort((a, b) => moment(a.createAt) - moment(b.createAt))
            .reverse(),
);

export const selectorGroupsGuestSorted = createSelector(
    selectorGroupsGuest,
    (state) => {
        const objCopy = Object.freeze([...state]);

        return objCopy
            .slice()
            .sort((a, b) => moment(a.createAt) - moment(b.createAt))
            .reverse();
    },
);

export default slice.reducer;
