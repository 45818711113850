import { createSlice, createSelector } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: "userSlice",
    initialState: {
        user: {},
        guestUsers: [],
        authState: {},
    },
    reducers: {
        getUserSlice: (state, action) => {
            state.user = action.payload;
        },
        getGuestUserSlice: (state, action) => {
            state.guestUsers = action.payload;
        },
        getAuthState: (state, action) => {
            state.authState = action.payload;
        },
    },
});

export const { getUserSlice, getGuestUserSlice, getAuthState } = slice.actions;

const selectUser = (state) => state.userStore.user;
const selectAuthState = (state) => state.userStore.authState;
const selectGuestUsers = (state) => state.userStore.guestUsers;

export const selectorUser = createSelector(selectUser, (state) => state);
export const selectorGuestUsers = createSelector(
    selectGuestUsers,
    (state) => state,
);

export const selectorAuthState = createSelector(
    selectAuthState,
    (state) => state,
);

export default slice.reducer;
